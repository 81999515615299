import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./pages/App";
import reportWebVitals from "./reportWebVitals";
import "@fontsource/baumans";
import "@fontsource/comfortaa";
import "@fontsource/caveat";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import AnalyticsWrapper from "./utils/google-analytics-lib/analytics-wrapper";

const theme = extendTheme({
  fonts: {
    logoFont2: `'Source Sans Pro', sans-serif`,
    logoFont4: `'Baumans', cursive`,
    logoFont: `"Comfortaa", sans-serif;`,
    CaveaFont: `'Caveat', cursive;`,
  },
  colors: {
    brand: {
      "50": "#E5EAFB",
      "100": "#D6E4FF",
      "200": "#ADC8FF",
      "300": "#84A9FF",
      "400": "#6690FF",
      "500": "#3366FF",
      "600": "#1f4ff6", //#254EDB
      "700": "#162F83",
      "800": "#162F83",
      "900": "#162F83",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <AnalyticsWrapper>
          <App />
        </AnalyticsWrapper>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
