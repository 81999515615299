import Features from "./features";
import Footer from "./footer";
import FooterSignupMessage from "./footer-signup-message";
import HeroContent from "./hero-content";
import Navbar from "./navbar";
import Pricing from "./pricing";
import ReviewsImportance from "./reviews-importance";
import DemoVideo from "./demo-video";
import WidgetLayout from "./widget-layout";
import Testimonials from "./testimontials";

export const Home = () => {
  return (
    <>
      <Navbar />
      <HeroContent />

      <ReviewsImportance></ReviewsImportance>
      <WidgetLayout />
      <Testimonials />
      <Features />
      <Pricing />
      {/* COMPARSION */}
      <FooterSignupMessage></FooterSignupMessage>
      <Footer></Footer>
    </>
  );
};
