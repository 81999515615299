import { Center, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from "@chakra-ui/react";

export default function DemoVideo({ isOpen, onClose }: { isOpen: any; onClose: any }) {
  return (
    <Modal closeOnOverlayClick={true} isOpen={isOpen} onClose={onClose} size="full" isCentered>
      <ModalOverlay bg="blackAlpha.700" />
      <ModalContent backgroundColor="transparent" w={{ base: "95%", md: "95%", lg: "70%" }}>
        <ModalCloseButton color={"white"} size={"2xl"} pt={"20px"} />
        <ModalBody paddingTop={{ base: "60%", md: "30%", lg: "10%" }}>
          <iframe
            width="100%"
            src="https://www.youtube.com/embed/GOLStYSnBdg?si=2qjMuLGACk2odpT3"
            title="Demo Video"
            style={{ border: "none", aspectRatio: "16/9" }}
            allow="accelerometer; 
            autoplay; 
            clipboard-write; 
            encrypted-media; 
            gyroscope; 
            picture-in-picture; 
            web-share"
            allowFullScreen
          ></iframe>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
