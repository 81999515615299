import React, { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom"; // Assuming you're using React Router

import { initGA, logPageView } from "./analytics";

export default function AnalyticsWrapper({ children }: { children: ReactNode }) {
  const location = useLocation();

  useEffect(() => {
    initGA();
  }, []);

  useEffect(() => {
    logPageView(location.pathname + location.search, document.title);
  }, [location]);

  return <>{children}</>;
}
