import { Button, Flex, Icon, Text, VStack, Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const FourOFour = () => {
  const navigate = useNavigate();
  const goToOrdersPage = () => {
    navigate("/orders");
  };

  return (
    <Flex h="100vh" justifyContent="center" alignItems="center">
      <VStack mt={"-20"}>
        <VStack>
          <Text as="b" fontSize={"2xl"}>
            Looks like you've stumbled upon a page that no longer exists.
          </Text>
          <img src="./404.jpg" width="30%" />
        </VStack>
        <Button color={"white"} px={4} size={"lg"} colorScheme={"brand"} onClick={goToOrdersPage}>
          Go to Homepage
        </Button>
      </VStack>
    </Flex>
  );
};
