export const handleContactClick = () => {
  window.location.href = "mailto:hello@brandzway.com";
};

export const smoothScrollToElement = (id: string) => {
  const targetElement = document.getElementById(id);
  if (targetElement) {
    targetElement.scrollIntoView({ behavior: "smooth" });
  }
};

export const openBrandzwayApp = () => {
  window.location.href = "https://app.brandzway.com/signup";
};
