import React, { useEffect } from "react";
import "../styles/App.css";
import { Route, Routes } from "react-router-dom";
import { Home } from "./home/home";
import PrivacyPolicy from "./legal/privacy-policy";
import TermsOfService from "./legal/terms-of-service";
import { FourOFour } from "./404";
import ComingSoon from "./coming-soon";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms" element={<TermsOfService />} />
      {/* <Route path="/coming-soon" element={<ComingSoon />} /> */}
      <Route path="*" element={<FourOFour />} />
    </Routes>
  );
}

export default App;
