import { Box, VStack, Text, Button, Heading, Highlight } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { openBrandzwayApp } from "../../utils/helper";
import { logEvent } from "../../utils/google-analytics-lib/analytics";

export default function FooterSignupMessage() {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        const isVisible = rect.top + rect.height * 0.1 < window.innerHeight && rect.bottom >= 0;
        setIsVisible(isVisible);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const signUpBtnClick = () => {
    openBrandzwayApp();
    logEvent("Footer", "Click", "Sign up free");
  };

  return (
    <Box py={20} px={10} ref={ref} bg={"#f3f7ff"}>
      <VStack spacing={"80px"} className={isVisible ? "animate-fade-down" : "opacity-zero"}>
        <VStack spacing={3}>
          <Heading lineHeight="tall" fontFamily={"heading"} textAlign={"center"} fontSize={{ base: "3xl", md: "3xl", lg: "4xl" }}>
            <Highlight query="" styles={{ px: "1", py: "1", rounded: "full", textDecoration: "underline", textDecorationStyle: "wavy", textDecorationColor: "brand.500", textDecorationThickness: "6px", textUnderlineOffset: "10px" }}>
              Want to take your brand to the next level?
            </Highlight>
          </Heading>
          <Heading lineHeight="tall" fontFamily={"heading"} textAlign={"center"} fontSize={{ base: "3xl", md: "3xl", lg: "4xl" }}>
            <Highlight query="Sign up now" styles={{ px: "1", py: "1", rounded: "full", textDecoration: "underline", textDecorationStyle: "wavy", textDecorationColor: "brand.500", textDecorationThickness: "6px", textUnderlineOffset: "10px" }}>
              Sign up now to get started!
            </Highlight>
          </Heading>
        </VStack>
        <Button
          _hover={{
            transform: "scale(1.1)",
          }}
          transition="transform 0.3s"
          rounded={"full"}
          size={"lg"}
          fontWeight={"semibold"}
          px={6}
          colorScheme={"brand"}
          width={"220px"}
          onClick={signUpBtnClick}
        >
          Get Started for free
        </Button>
      </VStack>
    </Box>
  );
}
