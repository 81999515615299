import ReactGA from "react-ga4";

const trackingId = "G-XF99VD8XWK";

export const initGA = () => {
  ReactGA.initialize([
    {
      trackingId,
    },
  ]);
};

export const logPageView = (page: string, title: string) => {
  ReactGA.send({ hitType: "pageview", page, title });
};

export const logEvent = (category: string, action: string, label?: string, value?: number) => {
  ReactGA.event({
    category,
    action,
    label,
    value,
  });
};
