import { ReactNode } from "react";
import { Box, Container, Stack, SimpleGrid, Text, Link, VisuallyHidden, chakra, useColorModeValue, Flex, Center, Spacer, HStack, VStack } from "@chakra-ui/react";
import { FaYoutube, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { handleContactClick, smoothScrollToElement } from "../../utils/helper";
import { logEvent } from "../../utils/google-analytics-lib/analytics";
import { log } from "console";

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

const SocialButton = ({ children, label, href }: { children: ReactNode; label: string; href: string }) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      target="_blank"
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer() {
  const navigate = useNavigate();
  const handleClick = (event: any, id: string) => {
    event.preventDefault();
    smoothScrollToElement(id);
  };

  return (
    <Box bg={"white"} pt={10}>
      <Container as={Stack} maxW={"8xl"} py={10} px={{ base: 10, md: 20 }}>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={8}>
          <Stack align={"flex-start"}>
            <Flex px={"5"} py={"2"} background={"brand.600"} borderRadius={"30px"}>
              <Center width={"100%"}>
                <Text fontSize="2xl" fontWeight="700" color="white" fontFamily={"logoFont"}>
                  brandzway
                </Text>
              </Center>
            </Flex>
            <Text p={"2"} color={"gray.600"} fontSize={"sm"}>
              Simplified Customer Reviews, Photos, Widgets and more.
            </Text>
          </Stack>
          <Spacer />
          <Spacer />

          <HStack align={"top"} spacing={12}>
            <VStack align={"left"}>
              <Text fontSize={"md"} as="b" mb={2}>
                Company
              </Text>
              <Link
                fontSize={"md"}
                onClick={() => {
                  handleContactClick();
                  logEvent("Footer", "Click", "Contact Us");
                }}
              >
                Contact Us
              </Link>
              <Link
                fontSize={"md"}
                onClick={(e) => {
                  handleClick(e, "reviews-features");
                  logEvent("Footer", "Click", "Features");
                }}
              >
                Features
              </Link>
              <Link
                fontSize={"md"}
                onClick={(e) => {
                  handleClick(e, "reviews-pricing");
                  logEvent("Footer", "Click", "Pricing");
                }}
              >
                Pricing
              </Link>
            </VStack>
            <VStack align={"left"}>
              <Text fontSize={"md"} as="b" mb={2}>
                Legal
              </Text>
              <Link
                fontSize={"md"}
                onClick={() => {
                  navigate("/privacy-policy");
                  logEvent("Footer", "Click", "Privacy Policy");
                }}
              >
                Privacy Policy
              </Link>
              <Link
                onClick={() => {
                  navigate("/terms");
                  logEvent("Footer", "Click", "Terms of Service");
                }}
                fontSize={"md"}
              >
                Terms of Service
              </Link>
              {/* <Link href={"#"} fontSize={"sm"}>
              Data Retention Policy
            </Link> */}
            </VStack>
          </HStack>
        </SimpleGrid>
      </Container>

      <Box borderTopWidth={1} borderStyle={"solid"} borderColor={useColorModeValue("gray.200", "gray.700")}>
        <Container as={Stack} maxW={"6xl"} py={8} direction={{ base: "column", md: "row" }} spacing={4} justify={{ md: "space-between" }} align={{ md: "center" }}>
          <Text>© 2023 Brandzway</Text>
          <Stack direction={"row"} spacing={6}>
            <SocialButton label={"X"} href={"https://twitter.com/BrandzwayApp"}>
              <FaXTwitter />
            </SocialButton>
            <SocialButton label={"YouTube"} href={"https://www.youtube.com/@Brandzway"}>
              <FaYoutube />
            </SocialButton>
            <SocialButton label={"Instagram"} href={"https://www.instagram.com/brandzwayapp"}>
              <FaInstagram />
            </SocialButton>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
