import { Box, Flex, Text, IconButton, Button, Stack, Collapse, Icon, Link, Popover, PopoverTrigger, PopoverContent, useColorModeValue, useDisclosure, Center } from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, ChevronDownIcon, ChevronRightIcon, ArrowForwardIcon, CheckCircleIcon, CheckIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { handleContactClick, openBrandzwayApp, smoothScrollToElement } from "../../utils/helper";
import { logEvent } from "../../utils/google-analytics-lib/analytics";

export default function Navbar() {
  const { isOpen, onToggle } = useDisclosure();
  const navigate = useNavigate();

  const getStartedForFreeBtnClick = () => {
    openBrandzwayApp();
    logEvent("Navbar", "Click", "Get Started for free");
  };

  return (
    <Box pt={"15"}>
      <Flex minH={"60px"} py={{ base: 2 }} px={{ base: 4 }} align={"center"} maxW={"8xl"} margin={"auto"}>
        <Flex flex={{ base: 1, md: 1 }} justify={{ base: "left", md: "start" }}>
          <Flex px={{ base: "4", lg: "8" }} py={"2"} background={"brand.600"} rounded={"full"} onClick={() => navigate("/")} cursor={"pointer"} boxShadow={"md"}>
            <Center width={"100%"}>
              <Text fontSize={{ base: "xl", md: "2xl", lg: "3xl" }} fontWeight="700" color="white" fontFamily={"logoFont"}>
                brandzway
              </Text>
            </Center>
          </Flex>

          <Flex display={{ base: "none", md: "flex" }} px={{ md: 3, lg: 10 }} ml={{ md: 4, lg: 10 }} border={"1px"} borderColor={"gray.300"} rounded={"full"}>
            <Flex justify={"flex-start"}>
              <DesktopNav />
            </Flex>
          </Flex>
        </Flex>

        <Stack display={{ base: "none", lg: "flex" }} justify={"flex-end"} direction={"row"} spacing={6}>
          <Button
            as={"a"}
            _hover={{
              bg: "black",
              transform: "scale(1.1)",
            }}
            transition="transform 0.3s"
            fontSize={"md"}
            fontWeight={600}
            backgroundColor={"gray.800"}
            color={"white"}
            borderRadius={"3xl"}
            px={10}
            onClick={getStartedForFreeBtnClick}
          >
            Login
          </Button>
        </Stack>
        <Flex ml={{ base: -2 }} display={{ base: "flex", md: "none" }} justify={"flex-end"}>
          <IconButton onClick={onToggle} icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />} variant={"ghost"} aria-label={"Toggle Navigation"} />
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue("black", "gray.200");
  const linkHoverColor = useColorModeValue("brand.600", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  const handleClick = (event: any, navItem: any) => {
    event.preventDefault();

    if (navItem.label === "Contact") {
      handleContactClick();
    } else {
      smoothScrollToElement(navItem.href);
    }

    logEvent("Navbar", "Click", navItem.label);
  };

  return (
    <Stack direction={"row"} spacing={4} alignItems={"center"}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Link
                p={2}
                onClick={(e) => handleClick(e, navItem)}
                fontSize={"md"}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
                {navItem.children && <ChevronDownIcon ml={"2"} />}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent border={0} boxShadow={"2xl"} bg={popoverContentBgColor} p={4} rounded={"xl"} minW={"xs"}>
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label }: NavItem) => {
  return (
    <>
      {label !== "More Coming Soon" && (
        <Button variant="ghost" role={"group"} display={"block"} p={2} rounded={"md"}>
          <Stack direction={"row"} align={"center"}>
            <Box>
              <Text transition={"all .3s ease"} _groupHover={{ color: "brand.600" }} fontWeight={500}>
                {label}
              </Text>
            </Box>
            <Flex opacity={1} justify={"flex-end"} align={"center"} flex={1}>
              <Icon color={"green.400"} w={5} h={5} as={CheckIcon} />
            </Flex>
          </Stack>
        </Button>
      )}
      {label === "More Coming Soon" && (
        <Button variant="ghost" cursor={"default"} role={"group"} display={"block"} p={2} rounded={"md"} _hover={{ bg: "white" }}>
          <Stack direction={"row"} align={"center"}>
            <Box>
              <Text transition={"all .3s ease"} fontWeight={400} fontSize={"1rem"} color="#706e6e">
                {label} 🔥
              </Text>
            </Box>
          </Stack>
        </Button>
      )}
    </>
  );
};

const MobileNav = () => {
  return (
    <Stack bg={useColorModeValue("white", "gray.800")} p={4} display={{ md: "none" }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();
  const handleClick = (event: any, label: any, href: any) => {
    event.preventDefault();

    if (label === "Contact") {
      handleContactClick();
    } else {
      smoothScrollToElement(href);
    }

    logEvent("Navbar", "Click", label);
  };

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        onClick={(e) => handleClick(e, label, href)}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text fontWeight={600} color={useColorModeValue("gray.600", "gray.200")}>
          {label}
        </Text>
        {children && <Icon as={ChevronDownIcon} transition={"all .25s ease-in-out"} transform={isOpen ? "rotate(180deg)" : ""} w={6} h={6} />}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack mt={2} pl={4} borderLeft={1} borderStyle={"solid"} borderColor={useColorModeValue("gray.200", "gray.700")} align={"start"}>
          {children &&
            children.map((child) =>
              child.label === "More Coming Soon" ? (
                <Text key={child.label} py={2}>
                  {child.label} 🔥
                </Text>
              ) : (
                <Link key={child.label} py={2} href={child.href} as="b">
                  {child.label} <Icon ml={"2"} mt={"-1"} color={"green.400"} w={4} h={4} as={CheckCircleIcon} />
                </Link>
              )
            )}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: "Products",
    children: [
      {
        label: "Reviews",
        subLabel: "Trending Design to inspire you",
        // href: "#",
      },
      {
        label: "More Coming Soon",
        subLabel: "Up-and-coming Designers",
        // href: "#",
      },
    ],
  },
  {
    label: "Pricing",
    href: "reviews-pricing",
  },
  {
    label: "Features",
    href: "reviews-features",
  },
  {
    label: "Contact",
    href: "mailto:your-email@example.com",
  },
];
