import { Box, Container, HStack, Heading, Highlight, Image, Link, Text, VStack, textDecoration } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

export default function ReviewsImportance() {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        const isVisible = rect.top + rect.height * 0.5 < window.innerHeight && rect.bottom >= rect.height * 0.2;
        setIsVisible(isVisible);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Box px={"40px"} py={"100px"} bg={"#fef6f4"} ref={ref} borderRadius={"50px"}>
      <VStack mb={"6"} spacing={"20"} textAlign={"center"}>
        <Heading lineHeight="tall" fontFamily={"heading"} textAlign={"center"} fontSize={{ base: "4xl", md: "4xl", lg: "5xl" }}>
          <Highlight query="Customer Reviews" styles={{ px: "1", py: "1", rounded: "full", textDecoration: "underline", textDecorationStyle: "wavy", textDecorationColor: "brand.500", textDecorationThickness: "8px", textUnderlineOffset: "4px" }}>
            How Can Customer Reviews Skyrocket Your Sales
          </Highlight>
        </Heading>
        <HStack justifyContent={"center"} className={isVisible ? "animate-fade-down" : "opacity-zero"}>
          <Image src="./assets/star2.png" boxSize={"54px"} />
          <Image src="./assets/star-smile2.png" boxSize={"54px"} />
          <Image src="./assets/star2.png" boxSize={"54px"} />
          <Image src="./assets/star-smile2.png" boxSize={"54px"} />
          <Image src="./assets/star2.png" boxSize={"54px"} />
        </HStack>
        <VStack>
          <Text fontSize={"2xl"} fontWeight={"semibold"} color={"gray.700"} className={isVisible ? "animate-fade-left" : "opacity-zero"}>
            98% of consumers feel that reviews are an essential resource when making purchase decisions.
          </Text>
          <Text fontSize={"2xl"} fontWeight={"semibold"} color={"gray.700"} className={isVisible ? "animate-fade-right" : "opacity-zero"}>
            Displaying reviews can increase sales by 270% on average.
          </Text>
          <Text fontSize={"xs"} color={"gray.500"} className={"animate-fade-down"}>
            (
            <Link href="https://www.powerreviews.com/insights/power-of-reviews-survey-2021/" isExternal>
              Source1
            </Link>
            {", "}
            <Link href="https://spiegel.medill.northwestern.edu/wp-content/uploads/sites/2/2021/04/Spiegel_Online-Review_eBook_Jun2017_FINAL.pdf" isExternal>
              Source2
            </Link>
            )
          </Text>
        </VStack>
      </VStack>
    </Box>
  );
}
