import { Container, Stack, Flex, Box, Heading, Text, Button, Image, Icon, IconProps, useColorModeValue, HStack, Tabs, TabList, Tab, TabPanels, TabPanel, TagLabel, Tag, VStack, Center, Modal, ModalOverlay, ModalContent, ModalBody, useDisclosure, Highlight } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { MdCreditCardOff } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { logEvent } from "../../utils/google-analytics-lib/analytics";
import { openBrandzwayApp } from "../../utils/helper";
import { FaCirclePlay } from "react-icons/fa6";
import DemoVideo from "./demo-video";
import { ArrowForwardIcon } from "@chakra-ui/icons";

export default function HeroContent() {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current && ref.current.getBoundingClientRect().top < window.innerHeight * 0.65) {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const signUpBtnClick = () => {
    openBrandzwayApp();
    logEvent("Hero Content", "Click", "Sign up free");
  };

  const handleWidgetLayoutTabChange = (index: number) => {
    const widgetLayout = index === 0 ? "Grid View" : index === 1 ? "List View" : "Star Ratings";
    logEvent("Hero Content", "Click", `Widget Layout - ${widgetLayout}`);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Container w={"100%"} maxW={"8xl"} pb={"150px"} overflow={"hidden"}>
      <Stack align={"center"} spacing={{ base: 8, md: 10 }} py={{ base: 20, md: 24 }} direction={{ base: "column", md: "row" }}>
        <Stack flex={1} spacing={"10"} className={"animate-fade-down"}>
          <HStack bg={"#fff5e3"} shadow={"md"} px={5} py={2} rounded={"full"} width={"300px"} justify={"center"} alignSelf={"center"}>
            <Text>Built exclusively for</Text>
            <Image height={"16px"} width="95px" src="./webflow_logo.png"></Image>
          </HStack>
          <Heading className="left-to-right-move-and-fading" lineHeight={1.1} fontWeight={700} fontSize={{ base: "5xl", sm: "7xl", lg: "8xl" }} textAlign={"center"} width="100%">
            <Text
              as={"span"}
              position={"relative"}
              _after={{
                content: "''",
                width: "full",
                height: "30%",
                position: "absolute",
                bottom: 1,
                left: 0,
                zIndex: -1,
              }}
            >
              Turn One-Time
            </Text>
            <br />
            <Text as={"span"} color={"brand.600"} bgGradient="linear(to-l, #7928CA, #FF0080)" bgClip="text">
              Shoppers into Loyal Customers
            </Text>
          </Heading>
          <Text color={"#232323"} fontSize={{ base: "xl", md: "2xl", lg: "2xl" }} fontWeight={"500"} textAlign={"center"}>
            Simplified Customer Reviews, Photos, Widgets and more.
          </Text>
          <Stack mt={"20px"} spacing={{ base: 4, sm: 6 }} direction={{ base: "column", sm: "column" }} align={"center"}>
            <VStack spacing={"4"}>
              <Button
                rounded={"full"}
                transform={"scale(1.25)"}
                size={"lg"}
                fontWeight={"semibold"}
                px={10}
                colorScheme={"brand"}
                width={"250px"}
                transition="transform 0.3s"
                _hover={{
                  transform: "scale(1.25)",
                }}
                onClick={signUpBtnClick}
                rightIcon={<ArrowForwardIcon className="left-to-right-move-and-fading" />}
              >
                Get Started for free
              </Button>
              <HStack>
                <Icon as={MdCreditCardOff} color={"gray.600"} fontSize={"lg"} mt={"0"} mr={"1"} />
                <Text fontSize={"sm"} justifyContent={"center"}>
                  {" "}
                  No credit card required.
                </Text>
              </HStack>
            </VStack>
          </Stack>
        </Stack>
      </Stack>
      <Flex
        w={"100%"}
        justify={"center"}
        cursor={"pointer"}
        onClick={() => {
          onOpen();
        }}
      >
        <Box boxShadow="-1px 0px 20px 5px #3e00ff29" position={"relative"} w={"100%"} maxW={"6xl"} aspectRatio={"1.6"} borderRadius={"20px"} overflow={"hidden"}>
          <Box bg={"gray.100"} w={"100%"} maxW={"6xl"} height={"35px"} borderTopRadius={"20px"}>
            <HStack height={"35px"} px={"20px"}>
              <Box bg={"red.400"} rounded={"full"} h={"12px"} w={"12px"}></Box>
              <Box bg={"yellow.400"} rounded={"full"} h={"12px"} w={"12px"}></Box>
              <Box bg={"green.400"} rounded={"full"} h={"12px"} w={"12px"}></Box>
            </HStack>
          </Box>
          <Box bg={"black"}>
            <Image src="./assets/hero-image2.png" opacity={0.95} />
          </Box>
          <Box position={"absolute"} top="50%" left="50%" transform="translate(-50%, -50%)">
            <Center className="zoom-in-out">
              <Box bg={"white"} rounded={"full"} w={{ base: "80px", md: "128px" }} h={{ base: "80px", md: "128px" }} position={"absolute"}></Box>
              <Icon as={FaCirclePlay} fontSize={{ base: "80", md: "128" }} color={"brand.500"} zIndex={"1"} />
            </Center>
          </Box>
        </Box>
      </Flex>
      <DemoVideo isOpen={isOpen} onClose={onClose} />
      <Box mt={"70px"}>
        <Text color={"#232323"} fontSize={{ base: "xl", md: "2xl", lg: "2xl" }} fontWeight={"600"} textAlign={"center"}>
          Simple, No-Code Solution. It Takes Only a Minute to Connect Brandzway to Your Site.
        </Text>
      </Box>
    </Container>
  );
}

export const Blob = (props: IconProps) => {
  return (
    <Icon width={"100%"} viewBox="0 0 578 440" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z" fill="currentColor" />
    </Icon>
  );
};
