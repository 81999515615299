import { Box, Flex } from "@chakra-ui/react";
import { useEffect } from "react";
import Footer from "../home/footer";
import Navbar from "../home/navbar";

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <Flex py={20} px={10} justifyContent={"center"}>
        <Box maxW={"5xl"}>
          <h3 style={{ textAlign: "center", fontSize: "32px", fontWeight: "bold", padding: "10px" }}>PRIVACY POLICY</h3>
          <div style={{ marginBottom: "1rem" }}>Your privacy is important to us, so Brandzway, a service provider based in the India, has created the following Privacy Policy (&quot;Policy&quot;) to let you know what information we collect when you visit our Site https://brandzway.com (&quot;Site&quot;), why we collect it and how we use it.</div>
          <div style={{ marginBottom: "1rem" }}>
            <div style={{ margin: ".5rem 0" }}>The terms &quot;You,&quot; &quot;Your,&quot; &quot;Yours&quot; and &quot;User&quot; refer to the entity/person/organization using our Site.</div>
            <div style={{ margin: ".5rem 0" }}>When this Policy mentions &quot;We&quot;, &quot;Us,&quot; and &quot;Our&quot; it refers to Brandzway and its subsidiaries and affiliates.</div>
          </div>
          <div style={{ marginBottom: "1rem" }}>This Privacy Policy is governed by our Terms of Services.</div>
          <div style={{ marginBottom: "1rem" }}>For any questions regarding this Policy or any requests regarding the processing of personal data, please contact us at hello@brandzway.com.</div>
          <div style={{ marginBottom: "1rem" }}>
            <h3 style={{ fontWeight: 700, margin: 0, textTransform: "uppercase" }}>1. INFORMATION WE COLLECT FROM YOU</h3>
            <div style={{ margin: ".5rem 0" }}>We collect the information You provide to us and this information is necessary for the adequate performance of the contractual arrangement which is in place between You and us and allow us to comply with our legal obligations.</div>
            <ul>
              <li>Account Signup Information. When You create the account, we ask You to provide the signup information, such as Email, Name, Surname, Passwords.</li>
              <li>Payment Information. To order and use features of Site, we may require you to provide certain financial information in order to facilitate the processing of payments. We collect your Credit or debit card number, Credit or debit card type, Credit or debit card expiration date, Billing address, Tax number, Name and surname.</li>
              <li>Login information. We collect Login information if You are logging to our account with Authentication Data.</li>
            </ul>
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <h3 style={{ fontWeight: 700, margin: 0, textTransform: "uppercase" }}>2. INFORMATION WE COLLECT AUTOMATICALLY</h3>
            <div style={{ margin: ".5rem 0" }}>When you use our Site or contact us directly we may collect information, including your personal information, about the way you act in our Site, the services You use and how You use them.</div>
            <div style={{ margin: ".5rem 0" }}>This information is necessary for the adequate performance of the contract between You and us, to enable us to comply with legal obligations and given our legitimate interest in being able to provide and improve the functionalities of the Site.</div>
            <ul>
              <li>Log data and Device information. We automatically collect log data and device information when you access and use the Site, even if you have not created an Account or logged in. That information includes, among other things: Internet protocol (IP) addresses, Browser type.</li>
              <li>Tracking technologies and Cookies. We use Cookies. We also automatically collect information about device’s operating system, .</li>
              <li>Geo-location data. We collect information about your approximate location as determined by data such as your IP address to offer you an improved user experience. Such data may be collected only when you access the Site using your device.</li>
              <li>
                Usage information. We use a tool called &quot;Google Analytics&quot; to collect information about your interactions with the Site (what pages you visit, such as the pages or content you view, your searches for Listings, bookings you have made, and other actions on the Site. In consequence, Google, Inc. plants a permanent cookie on your web browser to identify you as a unique user the next time you visit this Site). For more information please visit{" "}
                <a href="https://policies.google.com/privacy" target="_blank">
                  Google
                </a>
                .
              </li>
              <li>Publicly available personal Information.</li>
            </ul>
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <h3 style={{ fontWeight: 700, margin: 0, textTransform: "uppercase" }}>3. THE WAY WE USE YOUR INFORMATION</h3>
            <div style={{ margin: ".5rem 0" }}>We process your information adhering to the general data processing principles.</div>
            <div style={{ margin: ".5rem 0" }}>We may use the information we collect through our Site for a number of reasons, including to:</div>
            <ul>
              <li>to identify user</li>
              <li>to create account</li>
              <li>to create trusted environment</li>
              <li>to create statistics and analyze market</li>
              <li>to stay connected</li>
              <li>to customize marketing</li>
              <li>to send billing information</li>
              <li>to manage user orders</li>
              <li>to contact user</li>
              <li>to improve services</li>
              <li>to ensure data security and prevent fraud</li>
              <li>to comply with applicable laws</li>
              <li>to request feedback</li>
              <li>to post testimonials</li>
              <li>to provide support</li>
            </ul>
            <div style={{ margin: ".5rem 0" }}>We will normally collect personal information from you only where we have your consent to do so, where we need the personal information to perform a contract with you, or where the processing is in our legitimate business interests.</div>
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <h3 style={{ fontWeight: 700, margin: 0, textTransform: "uppercase" }}>4. DIRECT MARKETING</h3>
            <div style={{ margin: ".5rem 0" }}>We may use your provided contact details for direct marketing. These direct marketing offers, depending on your preferences, may be personalized taking into account any other information which you have provided to us (e.g. location, social media profile information, etc.) or we have collected or generated from other sources as described below.</div>
            <div style={{ margin: ".5rem 0" }}>If you wish to withdraw the consent for direct marketing, and refuse to receive information from us, you may exercise such option at any time you wish by sending an email to hello@brandzway.com.</div>
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <h3 style={{ fontWeight: 700, margin: 0, textTransform: "uppercase" }}>5. COOKIES</h3>
            <div style={{ margin: ".5rem 0" }}>Cookies are small text files stored by your browser on your computer when you visit our Site. We use cookies to improve our Site and make it easier to use. Cookies permit us to recognize users and avoid repetitive requests for the same information.</div>
            <div style={{ margin: ".5rem 0" }}>Cookies from our Site cannot be read by other Sites. Most browsers will accept cookies unless you change your browser settings to refuse them.</div>
            <div style={{ margin: ".5rem 0" }}>Cookies we use on our Site:</div>
            <ul>
              <li>Strictly necessary cookies - These cookies are required for the operation of our Site. They help us to show you the right information, customize your experience, and allow us to implement and maintain security features as well as to help us detect malicious activities. Without these cookies operation of the Website would be impossible or its functioning may be severely affected.</li>
            </ul>
            <div style={{ margin: ".5rem 0" }}>You may find more information about how to delete cookies, as well as the other useful information related to the use of the cookies, on the website http://www.allaboutcookies.org/.</div>
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <h3 style={{ fontWeight: 700, margin: 0, textTransform: "uppercase" }}>6. INFORMATION FROM MINORS</h3>
            <div style={{ margin: ".5rem 0" }}>This site and our Services are not intended for or directed to persons under the age of 18. We do not knowingly collect or ask for information from minors. We do not knowingly allow minors to use our Site or Services.</div>
            <div style={{ margin: ".5rem 0" }}>We will delete any information we discover is collected from a minor. Please contact us using the contact details below if you believe we might have information about a minor.</div>
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <h3 style={{ fontWeight: 700, margin: 0, textTransform: "uppercase" }}>7. SENSITIVE INFORMATION</h3>
            <div style={{ margin: ".5rem 0" }}>We do not collect sensitive information such as political opinions, religious or philosophical beliefs, racial or ethnic origin, genetic data, biometric data, health data or data related a sexual orientation.</div>
            <div style={{ margin: ".5rem 0" }}>Please do not send, upload, or provide us any sensitive data and contact us using the contact details below if you believe that we might have such information. We have a right to delete any information we believe it might contain sensitive data.</div>
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <h3 style={{ fontWeight: 700, margin: 0, textTransform: "uppercase" }}>8. PAYMENT INFORMATION</h3>
            <div style={{ margin: ".5rem 0" }}>To order and use our services we may require you to provide certain financial information to facilitate the processing of payments. We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your Personal information is governed by their Privacy Policy. All payment data is stored by . You may find their privacy policy link(s) here: .</div>
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <h3 style={{ fontWeight: 700, margin: 0, textTransform: "uppercase" }}>9. THIRD PARTY LINKS</h3>
            <div style={{ margin: ".5rem 0" }}>Our Site may have links to other websites. Please review their privacy policies to learn more about how they collect and use your personal data, because we do not control their policies and personal data processing practices.</div>
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <h3 style={{ fontWeight: 700, margin: 0, textTransform: "uppercase" }}>10. RETENTION</h3>
            <div style={{ margin: ".5rem 0" }}>We retain your personal information to provide services to you and as otherwise necessary to comply with our legal obligation, resolve disputes, and enforce our agreements.</div>
            <div style={{ margin: ".5rem 0" }}>We will retain your personal information as long as we need it to provide services to you, unless we are otherwise required by law or regulations to retain your personal information longer.</div>
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <h3 style={{ fontWeight: 700, margin: 0, textTransform: "uppercase" }}>11. SECURITY</h3>
            <div style={{ margin: ".5rem 0" }}>We have implemented security measures designed to protect the personal information you share with us, including physical, electronic and procedural measures. Among other things, we regularly monitor our systems for possible vulnerabilities and attacks.</div>
            <div style={{ margin: ".5rem 0" }}>Regardless of the measures and efforts taken by us, the transmission of information via internet, email or text message is not completely secure. We do not guarantee the absolute protection and security of your personal information or any other User Content you upload, publish or otherwise share with us or anyone else.</div>
            <div style={{ margin: ".5rem 0" }}>We therefore encourage you to avoid providing us or anyone with any sensitive information of which you believe its disclosure could cause you substantial or irreparable harm.</div>
            <div style={{ margin: ".5rem 0" }}>If you have any questions regarding the security of our Site or Services, you are welcome to contact us at hello@brandzway.com.</div>
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <h3 style={{ fontWeight: 700, margin: 0, textTransform: "uppercase" }}>12. YOUR RIGHTS</h3>
            <div style={{ margin: ".5rem 0" }}>You are entitled to a range of rights regarding the protection of your personal information. Those rights are:</div>
            <ul>
              <li>The right to access the information we have about you. If you wish to access your personal information that we collect, you can do so at any time by contacting us using the contact details provided below.</li>
              <li>The right to rectify inaccurate information about you. You can correct, update or request deletion of your personal information by contacting us using the contact details provided below.</li>
              <li>The right to object the processing. When we rely on your consent to process your personal information, you may withdraw consent at any time by contacting us using the contact details provided below. This will not affect the lawfulness of processing prior to the withdrawal of your consent.</li>
              <li>The right to lodge a complaint. You can raise questions or complaints to the national Data Protection Agency in your country of residence in the event where your rights may have been infringed. However, we recommend attempting to reach a peaceful resolution of the possible dispute by contacting us first.</li>
              <li>The right to erase any data concerning you. You may demand erasure of data without undue delay for legitimate reasons, e.g. where data is no longer necessary for the purposes it was collected, or where the data has been unlawfully processed.</li>
            </ul>
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <h3 style={{ fontWeight: 700, margin: 0, textTransform: "uppercase" }}>13. APPLICATION OF POLICY</h3>
            <div style={{ margin: ".5rem 0" }}>Our Policy does not apply to services offered by other companies or individuals, including products or sites that may be displayed to you in search results, sites that may include our services or other sites linked from our Site or Services.</div>
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <h3 style={{ fontWeight: 700, margin: 0, textTransform: "uppercase" }}>14. AMENDMENTS</h3>
            <div style={{ margin: ".5rem 0" }}>Our Policy may change from time to time. We will post any Policy changes on our Site and, if the changes are significant, we may consider providing a more explicit notice (including, for certain services, email notification of Policy changes).</div>
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <h3 style={{ fontWeight: 700, margin: 0, textTransform: "uppercase" }}>15. ACCEPTANCE OF THIS POLICY</h3>
            <div style={{ margin: ".5rem 0" }}>We assume that all Users of this Site have carefully read this document and agree to its contents. If someone does not agree with this Policy, they should refrain from using our Site. We reserve the right to change our Policy at any time and inform by using the way as indicated in Section 14. Continued use of this Site implies acceptance of the revised Policy.</div>
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <h3 style={{ fontWeight: 700, margin: 0, textTransform: "uppercase" }}>16. FURTHER INFORMATION</h3>
            <div style={{ margin: ".5rem 0" }}>If you have any further questions regarding the data we collect, or how we use it, then please feel free to contact us at the details as indicated above.</div>
          </div>
        </Box>
      </Flex>
      <Footer />
    </>
  );
}
