import { StarIcon } from "@chakra-ui/icons";
import { Box, HStack, Heading, Highlight, Icon, VStack, Text } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

export default function Testimonials() {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        const isVisible = rect.top + rect.height * 0.5 < window.innerHeight && rect.bottom >= rect.height * 0.2;
        setIsVisible(isVisible);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const reviews: any = [
    {
      comment: `The app was really easy to integrate, took us just a couple of minutesI We switched from another review app, which was quite a hassle, but Brandzway's simplicity and awesome features are just what we needed. Totally recommend it!`,
      name: "Stuti",
    },
    {
      comment: `I really love how the review emails can be customized to reflect my store branding perfectly. Brandzway is a game changer for my store and I think every shop should have it.`,
      name: "Aditya",
    },
    {
      comment: `I always read reviews before shopping, so I knew they'd be great for my store. Brandzway has been awesome for us on Webflow, and the photo reviews look amazing on the grid. Big thanks to the team for their fantastic support.`,
      name: "MK",
    },
  ];

  return (
    <Box px={{ base: "5px", md: "40px" }} py={"70px"} bg={"#fef6f4"} ref={ref} borderRadius={"50px"}>
      <Heading lineHeight="tall" fontFamily={"heading"} textAlign={"center"} fontSize={{ base: "4xl", md: "4xl", lg: "5xl" }}>
        <Highlight query="Love About Us" styles={{ px: "1", py: "1", rounded: "full", textDecoration: "underline", textDecorationStyle: "wavy", textDecorationColor: "brand.500", textDecorationThickness: "8px", textUnderlineOffset: "12px" }}>
          Hear What Our Customers Love About Us
        </Highlight>
      </Heading>
      <HStack justify={"center"} mt={"100px"} spacing={{ base: 1, md: 5 }} className={isVisible ? "animate-fade-down" : "opacity-zero"}>
        <TestimonialReview topMargin={10} className="down-to-up-move" review={reviews[0]} />
        <TestimonialReview topMargin={-10} className="up-to-down-move" review={reviews[1]} />
        <TestimonialReview topMargin={10} className="down-to-up-move" review={reviews[2]} />
      </HStack>
    </Box>
  );
}

const TestimonialReview = ({ topMargin, className, review }: { topMargin: number; className: string; review: any }) => {
  return (
    <Box bg={"#ffe5e5"} py={{ base: 5, md: 10 }} px={{ base: 3, md: 8 }} borderRadius={"20px"} maxW="sm" mt={topMargin} className={className}>
      <VStack align={"left"} spacing={{ base: 1, md: 5 }}>
        <HStack spacing={1}>
          <Icon as={StarIcon} boxSize={5} color={"#ffb33e"} />
          <Icon as={StarIcon} boxSize={5} color={"#ffb33e"} />
          <Icon as={StarIcon} boxSize={5} color={"#ffb33e"} />
          <Icon as={StarIcon} boxSize={5} color={"#ffb33e"} />
          <Icon as={StarIcon} boxSize={5} color={"#ffb33e"} />
        </HStack>
        <Text>{review.comment}</Text>
        <Text as="b">- {review.name}</Text>
      </VStack>
    </Box>
  );
};
